<template>
  <div>
    <b-card>
      <ValidationObserver
        ref="reportViewDetails"
      >
        <b-form class="mt-2">
          <b-row>

            <b-col md="6">
              <b-form-group
                label="Name"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Name"
                  :rules="{required: true, isUnique: reportViewId ? false : [allRreportViews]}"
                >
                  <b-form-input
                    v-model="reportViewName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Sql"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Sql"
                  rules="required"
                >
                  <AceEditor
                    v-model="reportViewQuery"
                    lang="sql"
                    width="100%"
                    height="200px"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
              <b-form-checkbox
                v-model="withPpa"
                class="d-inline-flex justify-content-center align-items-center"
              >
                With PPA
              </b-form-checkbox>
              <b-form-checkbox
                v-model="withTags"
                class="d-inline-flex justify-content-center align-items-center ml-2"
              >
                With Tags
              </b-form-checkbox>
              <b-form-checkbox
                v-model="withGroupTypes"
                class="d-inline-flex justify-content-center align-items-center ml-2"
                :disabled="withProgramFilter === false"
              >
                With Group Types
              </b-form-checkbox>
              <b-form-checkbox
                v-model="withProgramFilter"
                class="d-inline-flex justify-content-center align-items-center ml-2"
              >
                With Filter By Program Id
              </b-form-checkbox>
              <b-form-checkbox
                v-model="concatPrograms"
                class="d-inline-flex justify-content-center align-items-center ml-2"
              >
                Concat Programs
              </b-form-checkbox>
              <b-form-checkbox
                v-model="withApplicationsByProgram"
                class="d-inline-flex justify-content-center align-items-center ml-2"
                :disabled="concatPrograms === false"
              >
                With Applications Data By Program
              </b-form-checkbox>
            </b-col>

            <b-col
              cols="12"
              class="mt-2 d-flex justify-content-between"
            >
              <div
                v-if="validationErrors.length"
                class="form-group"
              >
                <p
                  v-for="(validationError, index) in validationErrors"
                  :key="index"
                  class="text-center text-danger"
                >
                  {{ validationError }}
                </p>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="mt-2 d-flex justify-content-between"
            >
              <b-button
                variant="primary"
                :disabled="isButtonDisabled || !reportViewQuery || isExecuting"
                @click="execute"
              >
                <b-spinner
                  v-if="isExecuting"
                  class="mr-1"
                  small
                />
                <span>Execute</span>
              </b-button>
              <div>
                <b-button
                  variant="primary"
                  class="mr-1"
                  :disabled="isButtonDisabled"
                  @click="saveReportView"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  :to="{ name: 'admin-reports-view-list' }"
                >
                  Cancel
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-card>
    <b-card
      v-if="executeResults.length > 0"
    >
      <b-table
        ref="reportTable"
        responsive
        :items="executeResults"
        :busy="isBusy"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #table-busy>
          <div class="my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BSpinner,
} from 'bootstrap-vue'

import AceEditor from 'vue2-ace-editor'

import 'brace/ext/language_tools'
import 'brace/mode/sql'
import 'brace/theme/chrome'
import 'brace/snippets/sql'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, isUnique } from '@validations'

import Ripple from 'vue-ripple-directive'
import useReportsView from '@/views/admin/reports-v2/reports-view/useReportsView'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
    BSpinner,

    AceEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reportViewId: this.$router.currentRoute.query.reportId,
      reportViewName: '',
      reportViewQuery: '',
      withPpa: false,
      withTags: false,
      withGroupTypes: false,
      withProgramFilter: false,
      concatPrograms: false,
      withApplicationsByProgram: true,
      allRreportViews: [],
      executeResults: [],
      sortBy: 'created_at',
      isSortDirDesc: true,
      isBusy: false,
      isButtonDisabled: false,
      required,
      isUnique,
      isExecuting: false,
      validationErrors: [],
    }
  },
  watch: {
    perPage() {
      this.updateTable()
    },
    currentPage() {
      this.updateTable()
    },
    withProgramFilter(val) {
      if (!val) {
        this.withGroupTypes = false
      }
      if (val) {
        this.concatPrograms = false
      }
    },
    concatPrograms(val) {
      if (val) {
        this.withProgramFilter = false
      }
      if (!val) {
        this.withApplicationsByProgram = false
      }
    }
  },
  async created() {
    if (this.reportViewId) {
      await this.fetchReportView(this.reportViewId).then(response => {
        this.reportViewName = response.name
        this.reportViewQuery = response.query
        this.withPpa = Boolean(response.with_ppa)
        this.withTags = Boolean(response.with_tags)
        this.withGroupTypes = Boolean(response.with_group_types)
        this.withProgramFilter = Boolean(response.with_program_filter)
        this.concatPrograms = Boolean(response.with_programs_concatenation)
        this.withApplicationsByProgram = Boolean(response.with_applications_by_program)
      })
    }

    await this.fetchReportViewsList().then(response => {
      this.allRreportViews = response
    })
  },
  setup() {
    const {
      fetchReportViewsList,
      fetchReportView,
      postReportView,
      updateReportView,
      executeReportView,
    } = useReportsView()

    return {
      fetchReportViewsList,
      fetchReportView,
      postReportView,
      updateReportView,
      executeReportView,
    }
  },
  methods: {
    saveReportView() {
      return new Promise((resolve, reject) => {
        this.$refs.reportViewDetails.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.reportViewId) {
              const queryParams = {
                id: this.reportViewId,
                name: this.reportViewName,
                query: this.reportViewQuery,
                with_ppa: this.withPpa,
                with_tags: this.withTags,
                with_group_types: this.withGroupTypes,
                with_program_filter: this.withProgramFilter,
                with_programs_concatenation: this.concatPrograms,
                with_applications_by_program: this.withApplicationsByProgram,
              }
              this.updateReportView(queryParams)
            } else {
              this.createReportView()
            }
          } else {
            reject()
          }
        })
      })
    },
    async createReportView() {
      const queryParams = {
        name: this.reportViewName,
        query: this.reportViewQuery,
        with_ppa: this.withPpa,
        with_tags: this.withTags,
        with_group_types: this.withGroupTypes,
        with_program_filter: this.withProgramFilter,
        with_programs_concatenation: this.concatPrograms,
        with_applications_by_program: this.withApplicationsByProgram,
      }
      await this.postReportView(queryParams)
    },
    async execute() {
      this.validationErrors = []
      this.isExecuting = true
      const queryParams = {
        query: this.reportViewQuery,
        with_ppa: this.withPpa,
        with_tags: this.withTags,
        with_group_types: this.withGroupTypes,
        with_program_filter: this.withProgramFilter,
        with_programs_concatenation: this.concatPrograms,
        with_applications_by_program: this.withApplicationsByProgram,
      }
      try {
        await this.executeReportView(queryParams)
          .then(response => {
            if (response.status === 422) {
              const validationErrors = Object.values(response.data.errors)
              this.validationErrors = validationErrors.flat()
            } else {
              this.executeResults = response
            }
          })
      // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isExecuting = false
      }
    },
    updateTable() {
    },
  },
}
</script>
